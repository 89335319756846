<template>
  <div class="supplierWaybillManagePage">
    <!-- 主体部分 -->
    <div class="facilityBox">
      <el-form ref="form" inline :model="searchForm" size="medium">
        <el-form-item label="企业名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入企业名称"
            clearable
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="提交时间"
          prop="InvoiceApplyTime"
          label-width="100px"
          style="margin-right:24px"
        >
          <el-date-picker
            v-model="searchForm.InvoiceApplyTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="search" size="medium">搜索</el-button>
        <el-button type="primary" @click="download" size="medium">下载</el-button>
      </el-form>
      <!-- 表格数据 -->
      <el-table
        v-loading="flag.loadingTableData"
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        @sort-change="sortChange"
      >
        <el-table-column
          fixed
          align="center"
          prop="index"
          label="序号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="InvoiceApplyDatetime"
          label="提交时间"
          width="200"
          sortable="custom"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PaymentDate"
          label="付款时间"
          width="200"
          sortable="custom"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="IsHZ"
          label="合作关系"
          width="130"
          sortable="custom"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.IsHZ == '已合作'" type="success">{{
              scope.row.IsHZ
            }}</el-tag>
            <el-tag v-else type="danger">{{ scope.row.IsHZ }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="IsPay"
          label="是否已支付"
          width="130"
          sortable="custom"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.IsPay" type="success">已支付</el-tag>
            <el-tag v-else type="danger">未支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="IsInvoicing"
          label="是否开票"
          width="130"
          sortable="custom"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.IsInvoicing" type="success">已开票</el-tag>
            <el-tag v-else type="danger">未开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TicketType"
          label="票种"
          width="130"
          sortable="custom"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BillsCount"
          label="总计运单数量"
          width="130"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CanhandleCount"
          label="待处理数量"
          width="130"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="invoicing(scope.row)" type="primary" size="small"
              >去开票</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 16px;"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAgentBySupplier,DownAgentBySupplier } from "@/api/waybill/pendingManage/index";
import { mapMutations } from "vuex";
import { columnCompare } from "@/utils/utils";
export default {
  data() {
    return {
      flag: {
        loadingTableData: false, //加载表格中
      },
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      searchForm: {
        name: "", //服务商名称
        InvoiceApplyTime: [],
      },
      tableData: [],
    };
  },
  methods: {
    ...mapMutations(["setInvoiceTaskID"]),
    //搜索服务商
    search() {
      this.pagination.page = 1;
      this.getAgentBySupplier();
    },
    download(){
      this.pagination.page = 1;
      let params = {
        PageIndex: this.pagination.page,
        PageSize: 100000,
        name: this.searchForm.name,
        phone: "",
        InvoiceApplyStartDatetime: this.searchForm.InvoiceApplyTime
          ? this.searchForm.InvoiceApplyTime[0]
          : "",
        InvoiceApplyEndDatetime: this.searchForm.InvoiceApplyTime
          ? this.searchForm.InvoiceApplyTime[1]
          : "",
      };
      DownAgentBySupplier(params).then((res)=>{
        window.location.href =res.data
      })
    },
    //去开票
    invoicing(item) {
      console.log("item", item);
      this.setInvoiceTaskID(item.InvoiceTaskID);
      this.$router.push({
        path: "/commission/pendingManage/pendingDetail",
        query: {
          enterpriseFullName: item.InvoiceApplicantUserID,
        },
      });
      sessionStorage.setItem(
        "searchForm",
        JSON.stringify({
          searchForm: this.searchForm,
          searchPage: this.pagination.page,
        })
      );
    },
    //获取表格数据
    getAgentBySupplier() {
      this.tableData = [];
      this.flag.loadingTableData = true;
      let params = {
        PageIndex: this.pagination.page,
        PageSize: 100000,
        name: this.searchForm.name,
        phone: "",
        InvoiceApplyStartDatetime: this.searchForm.InvoiceApplyTime
          ? this.searchForm.InvoiceApplyTime[0]
          : "",
        InvoiceApplyEndDatetime: this.searchForm.InvoiceApplyTime
          ? this.searchForm.InvoiceApplyTime[1]
          : "",
      };
      getAgentBySupplier(params)
        .then((res) => {
          this.tableData = res.data.DataList.map((item, index) => {
            item.index = index + 1;
            return item;
          });
          this.pagination.total = Number(res.data.TotalCount);
          console.log("this.tableData", this.tableData);
        })
        .finally(() => {
          this.flag.loadingTableData = false;
        });
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e;
    },
    sortChange({ prop, order }) {
      this.tableData.sort(columnCompare(prop, order));
    },
  },
  created() {
    // 路由跳转当前界面, 回显以前的查询条件
    let historySearch = JSON.parse(sessionStorage.getItem("searchForm"));
    if (historySearch) {
      console.log("historySearch", historySearch);
      this.searchForm = historySearch.searchForm;
      this.pagination.page = historySearch.searchPage;
    }
    this.getAgentBySupplier();
  },
};
</script>

<style lang="scss" scoped></style>
